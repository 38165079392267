import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import index from '@/views/index.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: index,
  },
  //产品中心
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Product/Product.vue'),
    meta: {
      title: '产品中心',
      keywords: '关键词1, 关键词2',
      description: '这是产品中心的描述'
    }
  },
  //产品详情2级
  {
    path: '/product/productDetails',
    name: 'productDetails',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Product/productDetails.vue')
  },
  //产品详情3级
  {
    path: '/product/productDetailsDeep',
    name: 'productDetailsDeep',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Product/productDetailsDeep.vue')
  },
  //膜应用
  {
    path: '/application',
    name: 'application',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Application/Application.vue')
  },
  //膜应用-新能源
  {
    path: '/application/applicationNewEnergy',
    name: 'applicationNewEnergy',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Application/applicationNewEnergy.vue')
  },
  //膜应用-新能源-详情
  {
    path: '/application/applicationNewEnergy/applicationNewEnergyDetails',
    name: 'applicationNewEnergyDetails',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Application/applicationNewEnergyDetails.vue')
  },
  //联系我们
  {
    path: '/contact_us',
    name: 'contact_us',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Contact_Us.vue')
  },
  //关于蓝然
  {
    path: '/aboutlanran',
    name: 'aboutlanran',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutLanran/AboutLanran.vue')
  },
  //关于蓝然-新闻详情页
  {
    path: '/aboutlanran/NewDetails',
    name: 'NewDetails',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutLanran/NewDetails.vue')
  },
  {
    path: '/aboutlanran/NewDetails/NewDetailsDeep',
    name: 'NewDetailsDeep',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutLanran/NewDetailsDeep.vue')
  },
  //搜索页面
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Search.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition;
    // } else {
    //   return { top: 0, left: 0 };
    // }
    if (to.hash) {
      return false;
    } else if (savedPosition) {
      return savedPosition;
    } else {  
      return { top: 0 };
    }
  },
})

export default router
